/*
* User submenu in main app navigation bar.
* */
const userMenu = () => {

  const trigger = document.querySelector('.p-header__user-menu');

  if (trigger) {
    const menu = [...trigger.parentNode.children].filter((child) => child !== trigger);

    trigger.addEventListener('click', function () {
      let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (windowWidth > 1090) {
        trigger.classList.toggle('active');
      }

    });

    // Close on window resize.
    window.addEventListener('resize', (e) => {
      trigger.classList.remove('active');
    })
  }


};
userMenu();


/*
* Universal layout methods.
***************************/

/*
* Closing sidebar box.
* */
function closeSidebar() {
  let buttonsClose = document.querySelectorAll('.c-sidebar__close');

  for (let buttonClose of buttonsClose) {
    buttonClose.addEventListener('click', function () {
      buttonClose.closest('.c-sidebar').classList.add('c-sidebar--hide');
    });
  }
}

closeSidebar();

/*
* Menu Mobile.
* */
const menuMobile = () => {
  const buttonTrigger = document.querySelector('.p-header__mobile-menu-btn');

  if (buttonTrigger) {
    const menu = document.querySelector('.p-header__mobile-wrapper');
    const menuOverlay = document.querySelector('.p-header__mobile-overlay');

    buttonTrigger.addEventListener('click', () => {
      buttonTrigger.classList.toggle('btn-mobi--active');
      menu.classList.toggle('p-header__mobile-wrapper--active');
      menuOverlay.classList.toggle('p-header__mobile-overlay--active');
    });

    // Close by esc key.
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        buttonTrigger.classList.remove('btn-mobi--active');
        menu.classList.remove('p-header__mobile-wrapper--active');
        menuOverlay.classList.remove('p-header__mobile-overlay--active');
      }
    });

    // Close on window resize.
    window.addEventListener('resize', () => {
      buttonTrigger.classList.remove('btn-mobi--active');
      menu.classList.remove('p-header__mobile-wrapper--active');
      menuOverlay.classList.remove('p-header__mobile-overlay--active');
    })
  }

};
menuMobile();

/*
* Language switcher menu.S
* */
const menuLanguage = () => {
  const trigger = document.querySelector('.c-lang-menu__trigger');

  if (trigger) {
    const menu = document.querySelector('.c-lang-menu__menu');

    // Set current language flag in header menu.
    const curFlag = document.querySelector('.c-lang-menu__cur-flag');
    const curFlagUrl = document.querySelector('.c-lang-menu__menu .lang-selected').src;

    if (curFlagUrl) {
      curFlag.style.backgroundImage = `url(${curFlagUrl})`;

      trigger.addEventListener('click', () => {
        let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        if (windowWidth > 830) {
          menu.classList.toggle('c-lang-menu__menu--active');
        }
      });

      // Close on window resize.
      window.addEventListener('resize', () => {
        menu.classList.remove('c-lang-menu__menu--active');
      })
    }
  }

};
menuLanguage();

/*
* Menu Map Options.
* */
const menuMapOptions = () => {
  const triggerButton = document.querySelector('.c-map-menu__button');
  if (triggerButton) {
    const menu = document.querySelector('.c-map-menu');

    triggerButton.addEventListener('click', () => {
      menu.classList.toggle('c-map-menu--active');
    });

    // Close on window resize.
    window.addEventListener('resize', () => {
      menu.classList.remove('c-map-menu--active');
    });

    // Close by esc key.
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        menu.classList.remove('c-map-menu--active');
      }
    });
  }
};
menuMapOptions();


/*
* Table Data - add labels to the table cells in mobile version.
* */
const tableDataMobileLabels = () => {
  const labels = Array.from(document.querySelectorAll('.c-data-table__header .header-item'));
  let labelsNames = [];
  for (let i = 0; i < labels.length; i++) {
    labelsNames.push(labels[i]['innerText']);
  }

  const rowsData = Array.from(document.querySelectorAll('.c-data-table__row'));

  rowsData.forEach((tableRow) => {
    const items = Array.from(tableRow.querySelectorAll('.row-item'));
    let itemCounter = 0;

    items.forEach((item) => {
      let label = document.createElement('div');
      label.classList.add('row-item__label');
      label.innerHTML = (labelsNames[itemCounter]) ? labelsNames[itemCounter] : '';

      let fragment = document.createDocumentFragment();
      fragment.appendChild(label);
      item.prepend(fragment);

      itemCounter++;
    });
  });
};
tableDataMobileLabels();


/*
* Table Data - add accordion in mobile version.
* */
const tableDataMobileAccordion = () => {
  let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const tableRows = Array.from(document.querySelectorAll('.c-data-table .c-data-table__row'));

  if ( tableRows.length ) {

    // Show table row after click.
    const showTableRowAfterClick = () => {
      tableRows.forEach((tableRow) => {
        tableRow.addEventListener('click', function () {
          let elementsToShow = Array.from(this.querySelectorAll('.item-accordion__content'));

          elementsToShow.forEach((item) => {
            item.classList.toggle('active');
            item.classList.toggle('hide');
          })
        });
      });
    };

    tableRows.forEach((tableRow) => {
      const rowItems = Array.from(tableRow.querySelectorAll('.row-item'));

      let itemsCounter = 0;
      rowItems.forEach((item) => {
        if (itemsCounter === 0) {
          item.classList.add('item-accordion__header');
        } else {
          item.classList.add('item-accordion__content');
        }

        itemsCounter++;
      });
    });

    // Function Hide all table rows.
    const hideAllTableRows = () => {
      const accordionContent = Array.from(document.querySelectorAll('.c-data-table .item-accordion__content'));

      accordionContent.forEach((item) => {
        item.classList.add('hide');
      });
    };

    // Hide all table rows at the start.
    if ( 993 > windowWidth) {
      // hideAllTableRows();
      showTableRowAfterClick();
    }

    // Function Show all table rows.
    const showAllTableRows = () => {
      const accordionContent = Array.from(document.querySelectorAll('.c-data-table .item-accordion__content'));

      accordionContent.forEach((item) => {
        item.classList.add('active');
        item.classList.remove('hide');
      });
    };

    window.addEventListener('resize', () => {
      let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if ( 992 < windowWidth) {
        showAllTableRows();
      }
    });

    /*
    * Accordion Buttons.
    * */
    const buttonShowAll = document.querySelector('.c-button--accordion-show');
    const buttonHideAll = document.querySelector('.c-button--accordion-hide');

    buttonShowAll.addEventListener('click', () => {
      showAllTableRows();
    });

    buttonHideAll.addEventListener('click', () => {
      hideAllTableRows();
    });

  } // end if ( tableRows.length )
};
tableDataMobileAccordion();
